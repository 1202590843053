import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/auth';
import nftDataReducer from './slices/nftData';

const reducer = {
  auth: authReducer,
  nftData: nftDataReducer,
};

const store = configureStore({
  reducer: reducer,
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
