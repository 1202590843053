import React, { useEffect, useState } from 'react';
import Logo from '../../MintComponents/Logo/Logo';
import Progress from '../../MintComponents/Progress/Progress';
import styles from './Loading.module.css';

import Star from '../../../../../assets/images/Star.png';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Loading = () => {
  let intervalId = 0;
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));
  const navigate = useNavigate();

  const [progress, setProgress] = useState(25);

  useEffect(() => {
    intervalId = setInterval(() => {
      setProgress((progress) => Math.min(progress + 25, 100));
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (progress === 100) {
      clearInterval(intervalId);
      navigate('/setting');
    }
  }, [progress]);

  return (
    <div className={styles.pageWrapper}>
      <Logo />
      <div className={styles.loadingWrapper}>
        <img src={Star} alt="start" className={styles[`opacity-${progress}`]} />
        <Progress
          value={progress}
          width={isTablet ? 290 : 550}
          height={isTablet ? 18 : 28}
          thickness={isTablet ? 3 : 4}
        />
      </div>
    </div>
  );
};

export default Loading;
