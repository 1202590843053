import React, { useRef, useEffect } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Box,
  Stack,
} from '@mui/material';
import styles from './DisclaimerModal.module.css';

const DisclaimerModal = ({ open, transition, handleClose }) => {

  const descriptionElementRef = useRef(null);
  const scroll = 'paper';

  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      TransitionComponent={transition}
      scroll={scroll}
      fullWidth={true}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle
        id="scroll-dialog-title"
        sx={{ backgroundColor: '#1d5051', color: 'white' }}
      >
        <Box>{'Disclaimer'}</Box>
      </DialogTitle>
      <DialogContent className={styles.content}>
        <Stack
          direction={'row'}
          flexWrap={'wrap'}
          justifyContent={'start'}
          mb={1}
          mt={1}
        >
          <Typography sx={{ fontSize: '15px', color: 'white' }}>
            This Mint Participation Agreement (the “Agreement”) is made and
            entered into as of the date of acceptance by the user (the
            “Participant”) and Moonizen Studios, Inc. (the “Company”). By
            clicking the “I Accept” button, the Participant agrees to be bound
            by the terms and conditions set forth in this Agreement.
          </Typography>
        </Stack>

        <Stack
          direction={'row'}
          flexWrap={'wrap'}
          justifyContent={'center'}
          mb={1.5}
        >
          <Typography
            sx={{ fontSize: '13px', fontWeight: 'bold', color: 'white' }}
          >
            No Purchase of Securities
          </Typography>

          <Typography sx={{ fontSize: '13px', mb: 1, color: 'white' }}>
            1.1 The Participant acknowledges and agrees that the tokens, coins,
            or other digital assets (collectively, the “NFTs”) offered in the
            Company’s Genesis collection launch (the “Mint”) do not constitute
            securities, and the purchase of Tokens or NFTs should not be
            considered an investment or purchase of shares, stocks, or any other
            form of securities in the Company.
          </Typography>
          <Typography sx={{ fontSize: '13px', mb: 1, color: 'white' }}>
            1.2 The Participant understands that the Tokens or NFTs are not
            registered under any securities laws, and the Company does not
            intend to register the Tokens or NFTs under any such laws. The NFTs
            are not intended to be, and should not be viewed as, a form of
            investment, an opportunity to obtain equity or any other ownership
            interest in the Company, or any promise of future returns.
          </Typography>

          <Typography
            sx={{ fontSize: '13px', fontWeight: 'bold', color: 'white' }}
          >
            No Guarantee of Liquidity
          </Typography>

          <Typography sx={{ fontSize: '13px', mb: 1, color: 'white' }}>
            2.1 The Participant acknowledges that the NFTs may not be easily
            tradable, and there may not be a liquid market for the NFTs at any
            given time
          </Typography>
          <Typography sx={{ fontSize: '13px', mb: 1, color: 'white' }}>
            2.2 The Company does not guarantee, represent or warrant that the
            NFTs will be tradable on any exchange or marketplace. The
            Participant understands that the value of the NFTs may fluctuate and
            that the Participant may not be able to sell or transfer the NFTs
            immediately or at any specific time in the future.
          </Typography>

          <Typography
            sx={{ fontSize: '13px', fontWeight: 'bold', color: 'white' }}
          >
            No Investment Advice
          </Typography>
          <Typography sx={{ fontSize: '13px', mb: 1, color: 'white' }}>
            3.1 The Company is not providing, and the Participant is not relying
            on, any advice or recommendation from the Company in connection with
            the purchase of NFTs or participation in the Mint.
          </Typography>
          <Typography sx={{ fontSize: '13px', mb: 1, color: 'white' }}>
            3.2 The Participant acknowledges that the Company has not provided
            any investment advice or made any recommendations regarding the
            suitability or potential value of the NFTs, and that the Participant
            is participating in the Mint based on their own independent judgment
            and risk assessment.
          </Typography>

          <Typography
            sx={{ fontSize: '13px', fontWeight: 'bold', color: 'white' }}
          >
            Participant Representations and Warranties
          </Typography>
          <Typography sx={{ fontSize: '13px', mb: 1, color: 'white' }}>
            4.1 The Participant represents and warrants that they are at least
            18 years of age, or the age of legal majority in their jurisdiction,
            and have the legal capacity to enter into this Agreement.
          </Typography>
          <Typography sx={{ fontSize: '13px', mb: 1, color: 'white' }}>
            4.2 The Participant represents and warrants that they are not
            purchasing the Tokens or NFTs as an investment and that their
            participation in the Mint is not based on any expectation of profit
            or financial return.
          </Typography>

          <Typography
            sx={{ fontSize: '13px', fontWeight: 'bold', color: 'white' }}
          >
            Governing Law and Jurisdiction
          </Typography>

          <Typography sx={{ fontSize: '13px', mb: 1, color: 'white' }}>
            5.1 This Agreement shall be governed by and construed in accordance
            with the laws of New Castle County, Delaware, without regard to
            conflict of law principles.
          </Typography>
          <Typography sx={{ fontSize: '13px', mb: 1, color: 'white' }}>
            5.2 Any disputes arising out of or in connection with this Agreement
            shall be subject to the exclusive jurisdiction of the courts of New
            Castle County, Delaware.
          </Typography>
        </Stack>

        <Stack
          direction={'row'}
          flexWrap={'wrap'}
          justifyContent={'center'}
          mb={1.5}
        >
          <Typography sx={{ fontSize: '13px', color: 'white' }}>
            By clicking “I Accept” below, the Participant acknowledges that they
            have read, understood, and agree to be bound by the terms and
            conditions of this Agreement.
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions className={styles.content}>
        <Button variant="contained" onClick={handleClose}>
          {'I Accept'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DisclaimerModal;
