import styled from '@emotion/styled';

const ClaimBoxStyled = styled.div`
  .carousel-container{
    width:100%;
  }

  .carousel-item{
    display: flex;
    justify-content:center;
    align-items: center;
  }

  .item-text{
    width: 171px;
    background: ${(props) => props.theme.palette.background.primary};
    font-size: 20px;
    text-align: center;
    color: #e6f4f1;
    font-family: "Poppins";
  }

  .item-image{
    display:flex;
    justify-content:center;
    width:100%;
  }

  .claim-box {
    padding: 30px 20px 30px 24px;
    border-radius: 4px;
    background: linear-gradient(
      135deg,
      rgba(7, 72, 91, 0.6) 0%,
      rgba(7, 72, 91, 0.2) 100%
    );

    .claim-box-content {
      padding: 10px 0px 0px 0px;
      position: relative;
      width: 100%;
      height: 270px;
      background: ${(props) => props.theme.palette.background.primary};
      border: 1px solid #000;
      background-blend-mode: multiply;

      .image {
        width: 100%;
        height: calc(100% - 30px);
      }

      .arrow {
        position: absolute;
        top: 50%;
        background: ${(props) => props.theme.palette.background.primary};
        border: 0px;
      }
      
      .right {
        right: 0;
      }
      
      .left {
        left: 0;
      }

      .claim-button-wrapper {
        position: absolute;
        width: fit-content;
        height: fit-content;
        left: 50%;
        bottom: -20px;
        transform: translate(-50%, 0px);

        .claim-button-on {
          border-radius: 10px;
          padding: 9px 58px;
          color: ${(props) => props.theme.palette.fontColor.primary};
          background: ${(props) => props.theme.palette.background.secondary};
          font-family: Space Ranger;
          font-size: 32px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.32px;
          text-transform: lowercase !important;
        }

        .view-claimable{
          border-radius: 10px;
          padding: 9px 58px;
          color: ${(props) => props.theme.palette.fontColor.primary};
          background: ${(props) => props.theme.palette.background.secondary};
          font-family: Space Ranger;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.32px;
          text-transform: lowercase !important;
        }

        .claim-button-off {
          border-radius: 10px;
          padding: 9px 58px;
          color: ${(props) => props.theme.palette.fontColor.primary};
          background: ${(props) => props.theme.palette.background.secondary};
          font-family: Space Ranger;
          font-size: 32px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.32px;
          text-transform: lowercase !important;
          filter: grayscale(100%);
        }
      }
    }

    .text-wrapper {
        width: 100%;
        height: fit-content;
        padding-top: 30px;
        color: ${(props) => props.theme.palette.fontColor.primary};

        .text{
          border: 1px solid #000;
          padding: 10px 15px;
          background: ${(props) => props.theme.palette.background.primary};
          font-size: 16px;
          text-align: center;
          color: #e6f4f1;
          font-family: "Poppins";
        }
    }
  }
`;

export default ClaimBoxStyled;
