import { Box, Stack } from '@mui/material';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useAccount } from 'wagmi';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import Home from '../pages/Home';
import Mint from '../components/Pages/Mint/MintPages/Mint/Mint.js';
import Loading from '../components/Pages/Mint/MintPages/Loading/Loading.js';
import MintResult from '../components/Pages/Mint/MintPages/MintResult/MintResult.js';
import MintSetting from '../components/Pages/Mint/MintPages/MintSetting/MintSetting.js';
import Dashboard from '../pages/Dashboard';
import MainFrameStyled from './MainFrame.style';
import { useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import TabPanel from '../components/TabPanel';
import Collection from '../pages/Collection';


const MainFrame = () => {
  const { isConnected } = useAccount();
  const [selectedPage, setSelectedPage] = useState(0);
  const [showSidebar, setShowSidebar] = useState(false);

  const onChangeSelectedPage = (page) => {
    setSelectedPage(page);
    setShowSidebar(false);
  };

  return (
    <MainFrameStyled>
      <Box>
        <Header showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
        <Stack className="main-wrapper" direction={'row'}>
          <Box className={`sidebar ${showSidebar ? 'show' : 'hide'}`}>
            <Sidebar
              selectedPage={selectedPage}
              setSelectedPage={onChangeSelectedPage}
              isConnected={isConnected}
            />
          </Box>
          <Box className="main-content">
            <SwipeableViews index={selectedPage}>
            <TabPanel value={selectedPage} index={0}>
              <Home />
            </TabPanel>
            <TabPanel value={selectedPage} index={1}>
              <BrowserRouter>
                <Routes>
                  <Route exact path="/" element={<Mint />} />
                  <Route exact path="/setting" element={<MintSetting />} />
                  <Route exact path="/loading" element={<Loading />} />
                  <Route exact path="/result" element={<MintResult />} />
                </Routes>
              </BrowserRouter>
            </TabPanel>
            <TabPanel disabled={true} value={selectedPage} index={2}>
              <Dashboard />
            </TabPanel>
            <TabPanel disabled={true} value={selectedPage} index={3}>
              <Collection />
            </TabPanel>
            </SwipeableViews>
          </Box>
        </Stack>
      </Box>
    </MainFrameStyled>
  );
};

export default MainFrame;
