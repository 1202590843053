import React from 'react';
import Typewriter from 'typewriter-effect';
import styles from './MessageConsole.module.css';
import Star from '../../../../../assets/images/Star-small.png';

const MessageConsole = ({ text, isFirstConsole }) => {
  return (
    <div className={styles.consoleWrapper}>
      <img src={Star} alt="star" className={styles.star} />
      <p
        className={
          !isFirstConsole ? styles.textWrapperConsole : styles.textWrapper
        }
      >
        {!isFirstConsole ? (
          <Typewriter
            onInit={(typewriter) => {
              typewriter.typeString(text).changeDelay(5).start();
            }}
          />
        ) : (
          text
        )}
      </p>
    </div>
  );
};

export default MessageConsole;
