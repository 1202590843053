import { InjectedConnector } from '@web3-react/injected-connector';

// import { http } from 'wagmi';
// import { getDefaultConfig } from '@rainbow-me/rainbowkit';
// import { mainnet, polygon } from '@wagmi/core/chains';
// import { coinbaseWallet, injected, safe, walletConnect } from '@wagmi/connectors';
// import { http, createConfig } from '@wagmi/core';
/* import { injected, metaMask, safe, walletConnect} from 'wagmi/connectors';
 */

import { createConfig, configureChains } from 'wagmi';
import { publicProvider } from 'wagmi/providers/public';
import { getDefaultWallets } from '@rainbow-me/rainbowkit';
import { connectorsForWallets } from '@rainbow-me/rainbowkit';
import { polygon, mainnet } from 'wagmi/chains';

import {
  injectedWallet,
  argentWallet,
  trustWallet,
  ledgerWallet,
  rainbowWallet,
  walletConnectWallet, 
  metaMaskWallet
} from '@rainbow-me/rainbowkit/wallets';

const projectId = 'b9dacde9577e9aa82c83cff5a8aee900';

// export const config = getDefaultConfig({
//   appName: 'RainbowKit Moonizen Media',
//   projectId,
//   chains: [polygon, mainnet],
//   walletList: {
//     wallets: [
//       walletConnectWallet,
//       metaMaskWallet,
//       argentWallet,
//       trustWallet,
//       ledgerWallet,
//       rainbowWallet,
//       injectedWallet
//     ],
//     groupName: 'Recommended',
//   },
//   transports: {
//     [polygon.id]: http(),
//     [mainnet.id]: http()
//   },
// }
// );

/* const connectors = connectorsForWallets([
  {
    groupName: 'Recommended',
    wallets: [walletConnectWallet, metaMaskWallet, argentWallet, trustWallet, ledgerWallet, rainbowWallet, injectedWallet ],
  },],
  {
    appName: 'RainbowKit Moonizen Media',
    projectId,
  }
); */

export const { chains, publicClient, webSocketPublicClient } = configureChains(
  [polygon, mainnet],
  [publicProvider()],
);

const { wallets } = getDefaultWallets({
  appName: 'RainbowKit Moonizen Media',
  projectId,
  chains,
});

const connectors = connectorsForWallets([
  ...wallets,
  {
    groupName: 'Other',
    wallets: [
      argentWallet({ projectId, chains }),
      trustWallet({ projectId, chains }),
      ledgerWallet({ projectId, chains }),
      injectedWallet({ chains }),
    ],
  },
]);

export const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
  webSocketPublicClient,
});

export const config = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
  webSocketPublicClient,
});

// export const wagmiConfig = createConfig({
//   chains: [polygon, mainnet],
//   connectors: [
//     injected(),
//     // walletConnect(projectId),
//     // safe(),
//     // coinbaseWallet()
//   ],
//   transports: {
//     [polygon.id]: http(),
//     [mainnet.id]: http()
//   }
// });

export const injected = new InjectedConnector({
  supportedChainIds: [1, 3, 4, 5, 42, 56, 97, 137, 1337, 31337, 80001],
});
