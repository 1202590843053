import { Box, Grid, InputBase, Stack, Typography } from '@mui/material';
import TraitsStyled from './Traits.styled';
import GradientBorderBox from '../../GradientBorderBox';

const Traits = (traits) => {
  return (
    <TraitsStyled>
      <Stack className="traits-box" spacing={1.5}>
        <Box className="title">
          <Typography>Traits</Typography>
        </Box>
        <Box className="traits-content">
          {/* <Grid container spacing={0}>
            <Grid item lg={8} md={8} sm={12}> */}
              <Box className="display-form">
                <Grid container columnSpacing={6.75} rowSpacing={5} sx={{marginLeft: 'auto', marginRight: 'auto'}}>
                  {traits.traits.map(item => (
                    <Grid item sx={{ paddingLeft:'50px' }}>
                      <Grid container columnspacing={0} direction="column" sx={{ borderRadius: 20, minWidth: 190}} wrap="nowrap">
                        <Grid item>
                          <Box className="display-form-title">
                            {item.trait_type}
                          </Box>
                        </Grid>
                        <Grid item>
                          <GradientBorderBox className="display-form-item">
                            <Typography className="item" noWrap>{item.value}</Typography>
                          </GradientBorderBox>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            {/* </Grid> */}
{/*             <Grid item lg={4} md={4} sm={12} xs={12}>
              <GradientBorderBox className="content">
                <Typography className="bold">Creator Earnings: 10%</Typography>
                <Typography>Token Standard: ERC-721</Typography>
                <Typography mt={1}>Chain: Polygon</Typography>
                <Typography mt={2} className="small">
                  0xc4e051863a646395b8503fb42dd1583398114454
                </Typography>
              </GradientBorderBox>
            </Grid> */}
          {/* </Grid> */}
        </Box>
      </Stack>
    </TraitsStyled>
  );
};

export default Traits;
