import { useState, useEffect } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { useAccount } from 'wagmi';
import { getMoonizensNFTContract, toBigNum, fromBigNum } from '../../../services/web3.service';

import MoonizensOwnedStyled from './MoonizensOwned.styled';
import ImageIconGenisis from '../../../assets/images/moonizens-genesis.svg';

const MoonizensOwned = ({isConnected}) => {

  const { address } = useAccount();  
  const [balance, setBalance] = useState("0");
  const signer = null;
  

  useEffect(() => {
    if (isConnected && balance == '0') {
      const fetchBalance = async () => {
        await getBalanceNFT();
      }
      fetchBalance();
  } else {
    setBalance('0');
  }
}, [isConnected]);


  const getBalanceNFT = async () => {
    try{
      const tokenContract = await getMoonizensNFTContract(signer);
      const balance_ = fromBigNum(toBigNum(await tokenContract.balanceOf(address)));
      setBalance(balance_);
    } catch(error) {
      console.error('Error fetching balance of NFTs:', error);
    }
  }

  return (
    <MoonizensOwnedStyled>
      <Stack
        className="moonizens-owned-box"
        direction={'column'}
        justifyContent={'space-around'}
      >
        <Box className="icon">
          <img src={ImageIconGenisis} alt="" />
        </Box>
        <Typography className="value">{balance}</Typography>
        <Typography className="desc">Moonizens Owned</Typography>
      </Stack>
    </MoonizensOwnedStyled>
  );
};

export default MoonizensOwned;
