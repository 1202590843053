/* eslint no-unused-vars:1 */
import { useState, useEffect } from 'react';
import { Box, Button, Stack } from '@mui/material';
import Swal from 'sweetalert2';
import { useAccount } from 'wagmi';
import { getMoonizensNFTClaimerContract, getEthersSigner } from "../../../services/web3.service";
// import { useEthersSigner } from '../../../services/provider.service';
import Carousel from 'react-multi-carousel';

import ClaimBoxStyled from './claimBox.styled';

import ClaimOne from '../../../assets/images/claim1-isometric-cubic.svg';
import ClaimTwo from '../../../assets/images/claim2-isometric-cubic.svg';
import ClaimThree from '../../../assets/images/claim3-isometric-cubic.svg';
import ForwardArrow from '../../../assets/images/right-arrow-claimable.png';
import BackArrow from '../../../assets/images/left-arrow-claimable.png';
import GradientBorderBox from '../../GradientBorderBox';
import { polygon } from '@wagmi/core/chains';



const ClaimBox = ({isConnected}) => {
  
  // const { signer } = useEthersSigner( {chainId: polygon.id} );
  const { address } = useAccount();
  const [ isMintEnabled, setIsMintEnabled ] = useState(true);
  const [ canClaim, setCanClaim ] = useState(true);
  const [ _quantities, setQuantities ] = useState();
  const [ contract, setContract ] = useState();
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  useEffect(()=>{
    if(isConnected){
      mintSwitch()
/*       checkClaim() */
    }
  },[isConnected])

  const CustomRight = ({ onClick }) => (
    <button className="arrow right" onClick={onClick}>
      <img src={ForwardArrow} alt="" />
    </button>
  );
  
  const CustomLeft = ({ onClick }) => (
    <button className="arrow left" onClick={onClick}>
      <img src={BackArrow} alt="" />
    </button>
  );

  const mintSwitch = async () => {
    let signer = null;

    try{
      signer = await getEthersSigner();
      // let signer = useEthersSigner( {chainId: polygon.id} );
    } catch(error){
      console.warn("Failed to get Ethers Signer", error)
    }
      const claimNFTContract = await getMoonizensNFTClaimerContract(signer);
      await claimNFTContract.mintingEnabled().then((result)=>{setIsMintEnabled(result)});
      setContract(claimNFTContract);
      const { quantities } = await claimNFTContract.getAvailableClaims(address);
      setQuantities(quantities);
      console.log("QTY: ", quantities )
      checkClaim(quantities);
  }

  const checkClaim = (quantities) => {
    if(quantities.length === 0 ){
      setCanClaim(false);
    }
    else if(quantities.length > 0){
      setCanClaim(true);
    }
    else{
      setCanClaim(false);
    }

  }

  const claimClicked = async () => {
    if (isConnected) {
      console.log("Claim Clicked");
      const signer = await getEthersSigner();
      console.log(signer)
      const claimContract = await getMoonizensNFTClaimerContract(signer);
      const { quantities } = await claimContract.getAvailableClaims(address);
      console.log(signer)
      console.log(quantities)
      let doClaim = false;
      for (let i = 0; i < quantities.length; i++){
        if(quantities[i]._hex > 0){
          doClaim = true;
          break;
        }
      }
      if( doClaim ) {
      // if (canClaim) {
        try {
          let tx = await claimContract.claimAndMint(signer._address);
          await tx.wait();
          Swal.fire({
            icon: 'success',
            title: 'Success! Check your wallet for your new NFTs.'
          });
          /* break; */
        } catch (error) {
          console.log(error)
          Swal.fire({
            icon: 'error',
            title: 'Network Error!'
          });
        }
      } else {
        Swal.fire({
          icon: 'warning',
          title: 'Please connect your wallet!',
        });
      }
    } else {
      Swal.fire({
        icon: 'warning',
        title: 'Please connect your wallet!',
      });
    }
  }

  const CustomRightArrow = ({ onClick, ...rest}) => {
    const {
      onMove,
      carouselState: {currentSlide, deviceType}
    } = rest;
    return <button onClick={() => onClick()} />;
  };

  return (
    <ClaimBoxStyled>
      <GradientBorderBox className="claim-box">
        <Box className="claim-box-content">
          <Stack
            className="image"
            alignItems={'center'}
            justifyContent={'center'}
          >
            <Carousel 
              responsive={responsive} 
              containerClass="carousel-container"
              itemClass="carousel-item"
              infinite
              autoPlay
              autoPlaySpeed={5000}
              customRightArrow={<CustomRight />} 
              customLeftArrow={<CustomLeft/>}
              draggable={false}>
              <Box>
                <Box className="item-text">Viridium Cometa</Box>
                <img className="item-image" src={ClaimOne} alt="" />
              </Box>
              <Box>
                <Box className="item-text">The Advent</Box>
                <img className="item-image" src={ClaimTwo} alt="" />
              </Box>
{/*               <Box>
                <Box className="item-text">Awakening</Box>
                <img className="item-image" src={ClaimThree} alt="" />
              </Box> */}
            </Carousel>
          </Stack>
          <Box className="claim-button-wrapper">
            {isMintEnabled ? (
              canClaim ? (
                <Button
                  className="claim-button-on gradient-border-box"
                  onClick={claimClicked}
                >CLAIM</Button>):/* (<></>) */(
                <Button
                  className="gradient-border-box view-claimable"
                  onClick={() =>{window.open(`https://opensea.io/${address}?search[collections][0]=moonizen-legacy`, "_blank", "noreferrer")}}
                  >VIEW COLLECTION</Button>)
            ):(
              <Button disabled
                className="claim-button-off gradient-border-box"
                onClick={claimClicked}
              >CLAIM</Button>
            )}
          </Box>

        </Box>          
        <Box className="text-wrapper">
          <Box className="text">
          {canClaim ? ("Click to claim exclusive NFT collectibles."
            ):(
              "All eligible NFTs have been claimed!"
            )}
          </Box>
        </Box>
      </GradientBorderBox>
    </ClaimBoxStyled>
  );
};

export default ClaimBox;
