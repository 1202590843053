import styled from '@emotion/styled';

const QuadrocarStyled = styled.div`
  padding: 6px 14px 22px 14px;
  height: 100%;

  .quadrocar-box {
    height: 100%;

    .title {
      color: ${(props) => props.theme.palette.fontColor.primary};
      font-family: Space Ranger;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.2px;
    }
    .quadrocar-items {
      .image-container{
        position: relative;
        display: inline-block;
        cursor: pointer;

        &:hover .overlay{
          opacity: 1;
        }

        .overlay{
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0,0,0,0.5);
          color: white;
          display: flex;
          justify-content: center;
          align-items: center;
          opacity: 0;
          transition: opacity 0.3s ease;
          border-radius: 20px;
        }
      }

      .quadrocar-item-on {
        width: 75px;
        height: 75px;
        border-radius: 20px;
        overflow: hidden;
        border: 3px solid #47c3c1;

        img {
          width: 100%;
          height: 100%;
        }
      }
      .quadrocar-item-off {
        width: 75px;
        height: 75px;
        border-radius: 20px;
        overflow: hidden;
        border: 3px solid #000000;

        img {
          width: 100%;
          height: 100%;
          filter: grayscale(100%);
        }
      }
    }
  }

  @media (max-width: 574px) {
    height: fit-content;

    .quadrocar-box {
      height: fit-content;
      .quadrocar-items {
        height: fit-content;
        flex-wrap: wrap;


        .image-container .overlay{
          height:60px;
          margin-top:10px;
        }
        

        .quadrocar-item-on {
          width: 60px;
          height: 60px;
          margin-top: 10px;
          flex: 0 0 auto;
        }

        .quadrocar-item-off {
          width: 60px;
          height: 60px;
          margin-top: 10px;
          flex: 0 0 auto;
        }
      }
    }
  }
`;

export default QuadrocarStyled;
