import { useState, useEffect } from 'react';
import copy from "copy-to-clipboard";
import { Button, Stack, Typography } from '@mui/material';


import AddressBoxStyled from './index.styled';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import GradientBorderBox from '../GradientBorderBox';

const AddressBox = ({ value, isConnected }) => {

  const [shortAdd, setShortAdd] = useState(value);

  useEffect(() => {
    if(isConnected){
    createShortAdd();
    } else{
      setShortAdd('0x00...0000');
    }
  }, [isConnected]);

  const createShortAdd = () => {
    if(typeof(value) === "string"){
      const shortie = value.toString().slice(0,4)+'...'+value.toString().slice(-4);
      setShortAdd(shortie); 
    }
    else{
      setShortAdd('0x00...0000');
    }
  }

  const copyToClipboard = () => {
    copy(value);
    alert('copied address to clipboard');
  }

  return (
    <AddressBoxStyled>
      <GradientBorderBox className="address-box">
        <Stack direction={'row'} spacing={1 / 2} alignItems={'center'}>
          <Typography className="address">{shortAdd}</Typography>
          <Button onClick={copyToClipboard}>
            <ContentCopyIcon className="icon" htmlColor="#FFF" />
          </Button>
        </Stack>
      </GradientBorderBox>
    </AddressBoxStyled>
  );
};

export default AddressBox;
