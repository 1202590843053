import React from 'react';
import { Box, LinearProgress } from '@mui/material';

const Progress = ({ width, value, height, thickness = 4 }) => {
  return (
    <Box sx={{ width }}>
      <LinearProgress
        variant="determinate"
        value={value}
        sx={{
          borderRadius: 50,
          border: `${thickness}px solid rgba(0, 0, 0, 0.12)`,
          height: height,
          '&.MuiLinearProgress-colorPrimary': {
            backgroundColor: 'white',
          },
          '& .MuiLinearProgress-bar': {
            borderRadius: 15,
            backgroundColor: '#47BCC3',
            height: height - thickness,
          },
        }}
      />
    </Box>
  );
};

export default Progress;
